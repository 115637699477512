<script setup>
import VueApexCharts from "vue3-apexcharts";
import abbreviate from "number-abbreviate";
import { debounce } from "lodash-es";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  totalClicks: {
    type: Number,
    required: true,
    default: 0,
  },
});

const seriesData = computed(() =>
  props.data.map((item) => ({
    x: item[0],
    y: abbreviate(item[2], 2),
  }))
);

const series = computed(() => [
  {
    data: seriesData.value,
  },
]);

const chartOptions = ref({
  legend: {
    show: false,
  },
  chart: {
    id: "cities-chart",
    height: 350,
    type: "treemap",
  },
  title: {
    text: "Cities Summary",
    margin: 20,
    offsetX: 0,
    offsetY: 2,
    style: {
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },
  noData: {
    text: "No regional data recorded.",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },
  plotOptions: {
    treemap: {
      distributed: true,
      enableShades: false,
    },
  },
});

const chartRef = ref(null);

// Debounce the update function
const debouncedUpdateData = debounce(() => {
  chartRef.value?.updateSeries([
    {
      data: seriesData.value,
    },
  ]);
}, 300);

watch(() => props.data, debouncedUpdateData, { immediate: true });

// Cleanup on unmount
onUnmounted(() => {
  debouncedUpdateData.cancel();
});
</script>

<template>
  <div class="h-full p-5">
    <VueApexCharts
      ref="chartRef"
      class="h-full"
      height="500"
      width="100%"
      type="treemap"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<style></style>
