<script setup>
import { GChart } from "vue-google-charts";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
});

const chunkSize = 1000;

const chartData = computed(() => {
  return [
    ["Country", "Clicks"],
    ...props.data
      .filter(([country]) => country !== "Not found")
      .map(([country, _, clicks]) => [country, clicks])
      .slice(0, chunkSize),
  ];
});

const chartOptions = ref({
  chart: {
    is3D: true,
    height: "100%",
  },
});
</script>
<template>
  <div class="h-full">
    <h1 class="pl-5 pt-5 text-left text-base font-bold h-[10%]">Regional Clicks</h1>
    <GChart
      type="GeoChart"
      class="h-[90%]"
      :data="chartData"
      :options="chartOptions"
      :settings="{ packages: ['geochart'] }"
      :resizeDebounce="500"
    />
  </div>
</template>

<style></style>
