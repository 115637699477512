<script setup>
import { TableRegions, TableStates, TableCities } from "#components";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  totalClicks: {
    type: Number,
    required: true,
    default: 0,
  },
  label: {
    type: String,
    required: true,
  },
  colKey: {
    type: String,
    required: true,
  },
});

const isDialogVisible = ref(false);

const getTableComponent = () => {
  switch (props.colKey) {
    case "regions":
      return TableRegions;
    case "states":
      return TableStates;
    case "cities":
      return TableCities;
    default:
      return null;
  }
};
</script>

<template>
  <div class="h-2"></div>
  <component
    :is="getTableComponent()"
    :data="data"
    :totalClicks="totalClicks"
    :isFullScreen="false"
  />

  <div v-if="totalClicks" class="w-full">
    <button
      @click="isDialogVisible = true"
      class="h-14 flex items-center w-full justify-center border-dashed border rounded-t-none rounded-xl"
    >
      <SvgExpand :height="16" :width="16" />
      <span class="ml-1.5">Expand</span>
    </button>
  </div>

  <Dialog
    :header="`${label} Clicks Data`"
    :modal="true"
    :dismissableMask="true"
    :visible="isDialogVisible"
    :maximizable="true"
    @update:visible="isDialogVisible = false"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '45vw' }"
    contentClass="!p-0"
    headerClass="!p-0"
  >
    <component
      :is="getTableComponent()"
      :data="data"
      :totalClicks="totalClicks"
      :isFullScreen="true"
    />
  </Dialog>
</template>

<style></style>
