<script setup>
import { FilterOperator, FilterMatchMode } from "primevue/api";
import { useAnalyticsStore } from "~/store/useAnalyticsStore.js";
import CountryFlag from "vue-country-flag-next";
import abbreviate from "number-abbreviate";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  totalClicks: {
    type: Number,
    required: true,
    default: 0,
  },
  isFullScreen: {
    type: Boolean,
    default: false,
  },
});

const statesTable = ref();
const statesData = computed(() =>
  props.data.map((item) => ({
    state: item[0],
    country_code: item[1],
    clicks: item[2],
  }))
);

const filters = ref({
  state: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
});

const useAnalytics = useAnalyticsStore();
const { addFilter } = useAnalytics;
function addToFilter(value) {
  addFilter("state", value);
}
</script>
<template>
  <DataTable
    :ref="statesTable"
    :value="statesData"
    dataKey="state"
    :paginator="false"
    :auto-layout="false"
    :resizableColumns="true"
    columnResizeMode="fit"
    :scrollable="true"
    responsiveLayout="scroll"
    :scrollHeight="isFullScreen ? '100%' : '32rem'"
    removableSort
    filterDisplay="menu"
    v-model:filters="filters"
    stateStorage="session"
    stateKey="states-table-session"
    class="remove-scroll text-gray-500 dark:text-gray-400 lg:min-h-[32rem]"
    id="state-table"
  >
    <template #empty>
      <h3 class="text-center select-none">No states data found.</h3>
    </template>

    <Column
      field="state"
      filterField="state"
      header="STATE"
      :sortable="true"
      headerClass="state-headerClass"
      bodyClass="bg-gray-50 dark:bg-[#1f1f1f] "
      style="width: 60% !important"
    >
      <template #body="{ data }">
        <div
          @click="addToFilter(data.state)"
          class="items-center flex font-medium text-gray-900 dark:text-white dark:bg-[#1f1f1f] text-sm whitespace-nowrap overflow-x-auto overflow-y-hidden cursor-pointer"
        >
          <div class="h-5 w-5">
            <country-flag :country="data.country_code" size="small" :rounded="false" />
          </div>
          <span class="ml-2 overflow-x-scroll remove-x-scroll break-words">
            {{ data.state }}</span
          >
        </div>
      </template>

      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          placeholder="Search State"
        />
      </template>
    </Column>
    <Column
      field="clicks"
      header="CLICKS"
      :sortable="true"
      headerClass="text-sm !text-gray-900 !bg-white dark:!bg[#181818]"
      style="width: 40% !important"
    >
      <template #body="{ data }">
        <span class="text-sm">
          <span class="font-medium mr-1">{{ abbreviate(data.clicks, 1) }}</span>
          <span class="text-xs"
            >({{ ((data.clicks / totalClicks) * 100).toFixed(2) + "%" }})</span
          >
        </span>
      </template>
    </Column>
  </DataTable>
</template>

<style lang="postcss">
.state-headerClass {
  @apply text-sm !text-gray-900 bg-gray-50 dark:bg-[#1f1f1f];
}
#state-table > .p-datatable-wrapper > table {
  @apply w-full !table-fixed;
}
</style>
